import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Routes } from '../../constants/enums';
import { messagingActions } from '../../reducers/messagingSlice';
import {
  resetSelectedRestaurant,
  resetSelectedRestaurantCode,
  updateAIEnabledStatus,
} from '../../reducers/restaurantSlice';
import { deleteUserSession } from '../../reducers/userSlice';
import { resetAI } from '../../redux/features/ai/ai.slice';
import { selectFeatureFlagTaskRouter } from '../../redux/features/config/config.selector';
import { TaskStatuses } from '../../redux/features/taskRouter/taskRouter.constants';
import {
  selectCurrentTask,
  selectCurrentTaskRestaurantCode,
} from '../../redux/features/taskRouter/taskRouter.selector';
import { updateTaskStatus } from '../../redux/features/taskRouter/taskRouter.slice';
import { OrderActionArgs } from '../../types';
import logger from '../../utils/logger';

const useTaskRouterHandlers = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentTaskId = useAppSelector(selectCurrentTask);
  const taskRouterFeatureFlag = useAppSelector(selectFeatureFlagTaskRouter);
  const isCurrentTaskFromTaskRouter = !!(
    taskRouterFeatureFlag && currentTaskId
  );
  const currentTaskRestaurantCode = useAppSelector(
    selectCurrentTaskRestaurantCode
  );

  const navigateToTaskRouterPage = () => {
    dispatch(resetSelectedRestaurant());
    dispatch(resetSelectedRestaurantCode());
    dispatch(updateAIEnabledStatus(false));
    dispatch(deleteUserSession());
    dispatch(messagingActions.closeConnection());
    dispatch(messagingActions.closeAudioWSConnection());
    dispatch(resetAI());
    logger.debug({
      message:
        'In navigateToTaskRouterPage: Before navigating to task-router page',
      taskId: currentTaskId,
      isTR: true,
    });
    if (currentTaskRestaurantCode === 'elc_qa_burgerking') {
      logger.debug({
        restaurantCode: currentTaskRestaurantCode,
        message: `Loading version=v1`,
      });
      window.location.replace(
        `${window.location?.origin}/${Routes.taskRouter}?version=v1`
      );
    } else {
      navigate(location.state?.from || '/' + Routes.taskRouter);
    }
  };

  const sendTaskStatus = (event: TaskStatuses, args?: OrderActionArgs) => {
    const {
      isCancelOrder,
      isFinishOrder,
      isStaffIntervention,
      isRestaurantStaffInterventionClicked,
    } = args || {};
    if (
      taskRouterFeatureFlag &&
      currentTaskId &&
      (isCancelOrder ||
        isFinishOrder ||
        isStaffIntervention ||
        isRestaurantStaffInterventionClicked)
    ) {
      dispatch(
        updateTaskStatus({
          taskId: currentTaskId,
          event,
        })
      );
    }
  };

  const endTask = () => {
    if (taskRouterFeatureFlag && currentTaskId) {
      navigateToTaskRouterPage();
    }
  };

  return {
    sendTaskStatus,
    isCurrentTaskFromTaskRouter,
    endTask,
    navigateToTaskRouterPage,
  };
};

export default useTaskRouterHandlers;
