enum AICommands {
  activate = '+ai',
  deactivate = '-ai',
  empty = '',
}

enum CartItemAdditionTypes {
  human = 'human',
  AI = 'ai',
}

enum EventTypes {
  start = 'start',
  audio = 'audio',
  text = 'text',
  info = 'info',
  endSession = 'end_session',
  order = 'order',
  orderStatus = 'order_status',
  loyalty = 'loyalty',
  loyaltyStatus = 'loyalty_status',
  hypothesis = 'hypothesis',
  startSession = 'start_session',
  carExit = 'car_exit',
  carEnter = 'car_enter',
  check = 'check',
  coupon = 'coupon',
  TTSRequest = 'tts_request',
  TTSOff = 'tts_off',
  TTSOn = 'tts_on',
  error = 'error',
  staffIntervention = 'staff_intervention',
  agentInterception = 'agent_interception',
  connect = 'connect',
  disconnect = 'disconnect',
  aiEscalation = 'ai_escalation',
  hitlSessionStart = 'hitl_session_start',
  hitlSessionEnd = 'hitl_session_end',
  aiStatus = 'ai_status',
  agentActivity = 'agent_activity',
  agentFirstActivity = 'agent_first_activity',
  hitlEventConnection = 'hitl_event_connection',
  dummyEvent = 'dummyEvent',
}

enum AgentTypes {
  HITL = 'HITL',
  RULAI = 'RULAI',
  prestoAI = 'PRESTOAI',
  GPT3 = 'GPT3', // Legacy and only used in LLM order event
  GPT4 = 'GPT4',
  orderBoard = 'ORDERBOARD',
  restaurantStaff = 'RESTAURANTSTAFF',
  taskManager = 'TASK_MANAGER',
  dialogManager = 'DIALOGMANAGER',
}

enum StatusResponseTypes {
  success = 'Success',
  error = 'Error',
}

enum CheckResponseTypes {
  ok = 'ok',
  error = 'error',
  unknown = 'unknown',
}

enum CouponResponseTypes {
  ok = 'ok',
  completed = 'COMPLETED',
  unknown = 'UNKNOWN',
  error = 'error',
}

enum EventSources {
  prestoVoice = 'PRESTOVOICE',
}

export {
  AICommands,
  CartItemAdditionTypes,
  EventTypes,
  AgentTypes,
  StatusResponseTypes,
  CheckResponseTypes,
  EventSources,
  CouponResponseTypes,
};
